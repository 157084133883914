import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const NobagDay = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/school-programs/Nobagday/4.webp`;
    
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }, 
    //     {
    //         src: p5,
    //         source: p5,
    //         rows: 2,
    //         cols: 1,
    //     },
    //     {
    //         src: p6,
    //         source: p6,
    //         rows: 2,
    //         cols: 1,
    //     }, 
    //     {
    //         src: p7,
    //         source: p7,
    //         rows: 1.5,
    //         cols: 1,
    //     },
    //     {
    //         src: p8,
    //         source: p8,
    //         rows: 1.5,
    //         cols: 1,
    //     },
    //     {
    //         src: p9,
    //         source: p9,
    //         rows: 1.5,
    //         cols: 1,
    //     },
    //     {
    //       src: p10,
    //       source: p10,
    //       rows: 1.5,
    //       cols: 1,
    //     }, 
    //     {
    //     src: p11,
    //     source: p11,
    //     rows: 1.5,
    //     cols: 2,
    //    },
    //    {
    //     src: p12,
    //     source: p12,
    //     rows: 1.5,
    //     cols: 2,
    //    },
    //    {
    //     src: p13,
    //     source: p13,
    //     rows: 1.5,
    //     cols: 2,
    //    },
    //    {
    //     src: p14,
    //     source: p14,
    //     rows: 1.5,
    //     cols: 2,
    //    }, 
    //    {
    //     src: p15,
    //     source: p15,
    //     rows: 2.6,
    //     cols: 2,
    //    },
    //    {
    //     src: p16,
    //     source: p16,
    //     rows: 1.7,
    //     cols: 2,
    //    }, 
    //    {
    //     src: p17,
    //     source: p17,
    //     rows: 1.5,
    //     cols: 2,
    //     },


    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                NO BAG DAY
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Class: 1-5  Date: 3 August 2024
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “If you believe in yourself and have dedication and determination and never quit, you'll be a winner. The price of victory is high but so are the rewards.”

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The school organized a Story Telling Session on account of “NO BAG DAY” for Classes 1-5 which was conducted by the resource person, Mr Muralidar Pai.  
                                    <br></br>
                                    Mr Muralidar Pai started the session by chanting of the shloka ‘Guru Brahma Guru Vishnu Guru Devo Maheshwaraha’ followed by the introduction of the ‘Pancha Bhoota’ are the five elements and stressed more on fire, water and wind to emphasize on their importance in our daily lives.
                                    He narrated different moral stories such as,
                                a.  The Mongoose and the Snake through which he stressed on “Not to jump to conclusions without   
                                    knowing the whole truth, how bravery and loyalty can save lives”.
                                b.  The Tortoise and the Two Cranes which stressed on the moral “Be caring and helpful”. “The     lesser you talk, the better it is”.
                                    <br></br>
                                    Many stories which stressed on the importance of being ‘Honest’ were also narrated.
                                    Finally, the session was well concluded by the Principal, who brought out the essence of being honest at various instances. Overall, the session was quite enriching, educative and helped in enhancing moral values among students.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ PEOPLE FORGET FACTS, BUT THEY REMEMBER STORIES ”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default NobagDay;